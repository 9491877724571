import axios from '@axios'

export default {
  namespaced: true,
  state: {
    modalityOptions: [
      { title: 'Presencial', value: 'face-to-face' },
      { title: 'Semipresencial', value: 'blended' },
      { title: 'Online', value: 'online' },
    ],
    qualificationTypes: [
      { title: 'Curso', value: 'course' },
      { title: 'Taller', value: 'workshop' },
    ],
    workshopQualy: [
      { title: 'Aprobado', value: 1 },
      { title: 'No aprobado', value: 0 },
    ],
  },
  getters: {},
  mutations: {},
  actions: {
    fetchGroups(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/group/getGroups`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/group/getGroup/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchYears(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/group/getGroupYears`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateGroup(ctx, groupData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/group/update/${groupData.group._id}`, groupData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGroup(ctx, groupData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/group/save`, groupData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/group/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFiles(ctx, files) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/group/uploadFiles`, files)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateGroupStudents(ctx, { params, group }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/group/updateGroupStudents/${group}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateClassDays(ctx, { params, group }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/group/updateClassDays/${group}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateScholarship(ctx, scholarshipData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/scholarship/update/${scholarshipData._id}`, scholarshipData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateScholarship(ctx, { student, group }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/scholarship/generate`, { student, group })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    generateCertificate(ctx, { student, group }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/certificate/generate`, { student, group })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCertificate(ctx, certificateData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/certificate/update/${certificateData._id}`, certificateData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    downloadCertificate(ctx, file) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/certificate/${file}`, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
