var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',{attrs:{"label":"Empresa","items":_vm.companiesListTable,"item-text":"name","item-value":"_id","no-data-text":"No hay resultados","search-input":_vm.searchInput,"disabled":_vm.disabled,"rules":_vm.$props.rules,"outlined":"","dense":"","clearable":"","filter":(item, queryText, itemText) => {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.rut.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.fantasyName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    }},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"change":_vm.onSelection},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(data.item.rut)+" ")])],1)]]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }