<template>
  <v-row class="mt-1">
    <v-col cols="12">
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-btn color="error" class="mb-4 mr-10" v-show="selectedRows.length" small @click="deleteClassMany">
          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
          <span>Eliminar Seleccionados</span>
        </v-btn>

        <!-- search -->
        <v-text-field v-model="search" placeholder="Buscar" outlined hide-details dense class="user-search me-3 mb-4">
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="addItem">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Clase</span>
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        v-model="selectedRows"
        item-key="_id"
        :headers="headers"
        :items="classDays"
        :search="search"
        show-select
        sort-by="start"
        :footer-props="{
          'items-per-page-text': 'Días por página: ',
          'items-per-page-options': [15, 30, 50, 100],
        }"
      >
        <template #[`item.fecha`]="{ item }">
          <span>{{ date(item.start).format('DD/MM/YYYY') }}</span>
        </template>
        <template #[`item.hora`]="{ item }">
          <span>{{ date(item.start).format('HH:mm') }} - {{ date(item.end).format('HH:mm') }}</span>
        </template>
        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-icon small class="me-2" @click="editItem(item)">
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data> No hay clases para mostrar </template>
      </v-data-table>
    </v-col>

    <!-- dialog -->
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Clase {{ editedItem.name }}</span>
        </v-card-title>
        <v-form v-model="valid" ref="form" class="multi-col-validation" @submit.prevent="save" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <simple-date-picker
                    v-model="editedItem.date"
                    :rules="[validators.required]"
                    required
                    label="Fecha"
                  ></simple-date-picker>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <time-pickers
                    v-model="editedItem.from"
                    :rules="[validators.required]"
                    required
                    label="Hora Desde"
                  ></time-pickers>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <time-pickers
                    v-model="editedItem.to"
                    :rules="[validators.required]"
                    required
                    label="Hora Hasta"
                  ></time-pickers>
                </v-col>

                <v-col cols="12" sm="6" md="4" v-if="editedIndex == -1">
                  <v-switch v-model="repeat" inset label="Repetir"></v-switch>
                </v-col>

                <v-row v-show="repeat">
                  <v-col cols="12" sm="6" md="6">
                    <v-combobox
                      v-model="editedItem.daysSelected"
                      :items="days"
                      item-text="title"
                      item-value="value"
                      label="Repetir los:"
                      :rules="repeat ? [validators.required] : []"
                      multiple
                      :required="repeat"
                      chips
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" sm="4" md="2">
                    <v-text-field
                      v-model.number="editedItem.weeksSelected"
                      :rules="repeat ? [validators.required, validators.integerValidatorThanZero] : []"
                      label="Cada"
                      type="number"
                      suffix="semana(s)"
                      :required="repeat"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <simple-date-picker
                      v-model="editedItem.dateTo"
                      :rules="repeat ? [validators.required] : []"
                      :required="repeat"
                      label="Fecha Hasta"
                    >
                    </simple-date-picker>
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="close"> Cancelar </v-btn>
            <v-btn color="success" type="submit"> Guardar </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import snackbarPlugin from '@/plugins/snackbar'
import SimpleDatePicker from '@/views/components/SimpleDatePicker.vue'
import TimePickers from '@/views/components/TimePickers.vue'
import { integerValidatorThanZero, required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { inject, ref, watch } from 'vue'
import { ObjectID } from 'bson'

export default {
  components: {
    TimePickers,
    SimpleDatePicker,
  },
  props: {
    value: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const classDays = ref(JSON.parse(JSON.stringify(props.value)))

    const selectedRows = ref([])

    const search = ref('')
    const date = inject('date')

    const editedIndex = ref(-1)
    const editedItem = ref({})
    const defaultItem = {
      date: null,
      from: null,
      to: null,
      daysSelected: [],
      weeksSelected: null,
      dateTo: null,
    }

    const dialog = ref(false)
    const dialogDelete = ref(false)
    const valid = ref(false)

    const headers = [
      { text: 'Fecha', value: 'fecha' },
      { text: 'Hora', value: 'hora' },
      { text: 'Acciones', value: 'actions' },
    ]

    const form = ref(null)

    const reset = () => {
      form.value.reset()
    }

    const resetValidation = () => {
      form.value.resetValidation()
    }

    const validate = () => {
      form.value.validate()
    }

    const addItem = () => {
      editedItem.value = { ...defaultItem }
      editedIndex.value = -1
      repeat.value = false
      dialog.value = true
    }

    const editItem = item => {
      editedIndex.value = classDays.value.indexOf(item)
      editedItem.value = {
        date: date(item.start).format('YYYY-MM-DD'),
        from: date(item.start).format('HH:mm'),
        to: date(item.end).format('HH:mm'),
      }
      repeat.value = false
      dialog.value = true
    }

    const deleteItem = async item => {
      classDays.value.splice(classDays.value.indexOf(item), 1)
      emit('input', classDays.value)
    }

    const deleteClassMany = async () => {
      selectedRows.value.forEach(row => {
        classDays.value.splice(classDays.value.indexOf(row), 1)
      })
      selectedRows.value = []
      emit('input', classDays.value)
    }

    const close = () => {
      dialog.value = false
    }

    const deleteChip = index => {
      editedItem.value.syllabus.splice(index, 1)
    }

    const save = () => {
      if (repeat.value) {
        if (valid.value) {
          if (date(editedItem.value.dateTo) > date(editedItem.value.date)) {
            let weekTotal = date(editedItem.value.dateTo).diff(editedItem.value.date, 'week')

            if (
              (editedItem.value.weeksSelected == 1 && weekTotal == 0) ||
              editedItem.value.weeksSelected <= weekTotal
            ) {
              for (let week = 0; week <= weekTotal; week += editedItem.value.weeksSelected) {
                editedItem.value.daysSelected.forEach(day => {
                  let dayToSave = date(editedItem.value.date).add(week, 'week').day(day.value)
                  if (
                    dayToSave >= date(editedItem.value.date) &&
                    dayToSave <= date(editedItem.value.dateTo).hour(23).minute(59)
                  )
                    classDays.value.push({
                      start: date(dayToSave.format('YYYY-MM-DD') + ' ' + editedItem.value.from).toISOString(),
                      end: date(dayToSave.format('YYYY-MM-DD') + ' ' + editedItem.value.to).toISOString(),
                    })
                })
              }
              emit('input', classDays.value)
              close()
            } else
              snackbarPlugin.showMessage({
                content: 'Verifique la cantidad de semanas',
                color: 'error',
                timeout: 10000,
              })
          } else
            snackbarPlugin.showMessage({
              content: 'Verifique las fechas seleccionadas',
              color: 'error',
              timeout: 10000,
            })
        }
      } else {
        let start = new Date(editedItem.value.date + ' ' + editedItem.value.from)
        let end = new Date(editedItem.value.date + ' ' + editedItem.value.to)

        if (valid.value && end > start) {
          if (editedIndex.value > -1) {
            Object.assign(classDays.value[editedIndex.value], { start: start.toISOString(), end: end.toISOString() })
          } else {
            classDays.value.push({ start: start.toISOString(), end: end.toISOString() })
          }
          emit('input', classDays.value)
          close()
        } else {
          validate()
        }
      }
    }

    watch(dialog, value => {
      value || close()
    })

    watch(dialogDelete, value => {
      value || close()
    })

    watch(
      () => props.value,
      value => {
        classDays.value = value
      },
    )

    const repeat = ref(false)
    const days = [
      { title: 'Lunes', value: '1' },
      { title: 'Martes', value: '2' },
      { title: 'Miércoles', value: '3' },
      { title: 'Jueves', value: '4' },
      { title: 'Viernes', value: '5' },
      { title: 'Sábado', value: '6' },
      { title: 'Domingo', value: '7' },
    ]

    return {
      date,
      days,
      repeat,
      classDays,
      form,
      search,
      dialog,
      dialogDelete,
      editedIndex,
      editedItem,
      defaultItem,
      deleteItem,
      deleteChip,
      save,
      close,
      headers,
      editItem,
      addItem,
      valid,
      validate,
      validators: { required, integerValidatorThanZero },
      selectedRows,
      deleteClassMany,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
      },
    }
  },
}
</script>
