import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCompanies(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/company/`, { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompany(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/company/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCompany(ctx, companyData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/company/${companyData.company._id}`, companyData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCompany(ctx, companyData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/company/`, companyData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCompany(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/company/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadLogo(ctx, file) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/company/uploadLogo`, file)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
