export const exportData = async (data, filename) => {
  const csvHeader = Object.keys(data[0]).join(';') + '\n'

  data = data.map(d => Object.keys(d).map(e => d[e]))

  let csvContent = 'data:text/csv;charset=utf-8,' + csvHeader + data.map(e => e.join(';')).join('\n')

  var encodedUri = encodeURI(csvContent)
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}
